import Navbar from "@/components/Navbar"
import { Button } from "@/components/ui/button"
import { Code, TicketCheck } from "lucide-react" 
import { LoginForm } from "@/components/login-form"


function Welcome() {
  return (
    <div className="h-full ">
      <header className="header">
        <Navbar />
      </header>

      <div className="flex flex-col md:flex-row justify-between my-12">
        <div className="flex flex-[1.5] flex-col items-start border p-4">
          <p className="text-3xl font-bold text-left">Welcome</p>
          <p className="text-left text-sm w-[80%]">
            In this eBook, you get a breakdown of how major business budgets are directed to less effective causes, and how to make your business save more.
          </p>

          <p className="text-xl mt-4 font-bold">What's included</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full p-4">
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
          </div>


          <p className="text-xl mt-4 font-bold">Who is this book for?</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full p-4  ">
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
          </div>
          <div className="flex justify-center w-full">
            <Button variant="greengrape" size="lg" className="w-full">
              <a className="text-white font-bold" href="https://www.greengrapedigital.com/ebook.pdf">Get PDF</a>
            </Button>
          </div>
        </div>

        <div className="flex-1 ebook max-h-[200px]">
          <div className=" relative  ">
            <div className="absolute -top-10 -right-2 rounded-full bg-green-800 w-24 h-24 hidden md:flex justify-around items-center">
              <p className="p-2 text-white">NEW RELEASE!</p>
            </div>
            <div className="hidden md:inline-block  object-cover w-full">
              <img className="h-full object-contain w-full max-h-[700px] shadow-md shadow-slate-500" src='/img/book-cover.png' />
            </div>
          </div>
        </div>
      </div>
 
    </div>
  )
}

export default Welcome