import Navbar from "@/components/Navbar"
import { Button } from "@/components/ui/button"
import { Code, TicketCheck } from "lucide-react"
import CountdownTimer from "./components/CountdownTimer"
import { LoginForm } from "@/components/login-form"

function getNext10PM() {
  const now = new Date();
  const today10PM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22, 0, 0, 0); // Today at 10 PM

  // Check if the current time is past 10 PM
  if (now > today10PM) {
    // Set the start time to 10 PM tomorrow if it's already past 10 PM today
    return new Date(today10PM.getTime() + 24 * 60 * 60 * 1000);
  }

  // Otherwise, set it to 10 PM today
  return today10PM;
}

function Ebook() { 

const startTime = getNext10PM();
  return (
    <div className="h-full ">
      <header className="header">
        <Navbar />
      </header>

      <div className="flex flex-col md:flex-row justify-between my-12">
        <div className="flex flex-[1.5] flex-col items-start border p-4">
          <p className="text-3xl font-bold text-left">Stop wasting <span className="text-green-600">40%</span> of your marketing budget</p>
          <p className="text-left text-sm w-[80%]">
            In this eBook, you get a breakdown of how major business budgets are directed to less effective causes, and how to make your business save more.
          </p>

          <p className="text-xl mt-4 font-bold">What's included</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full p-4">
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>PPC Marketing Strategies</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>SEO Optimization </p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Financial Guidelines for business</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Product Market Fit Analysis</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p className="font-bold">and many more ...</p>
            </div>
          </div>


          <p className="text-xl mt-4 font-bold">Who is this book for?</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full p-4  ">
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Startup Founders</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Large Scale businesses</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business Enthusiasts</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Solopreneurs</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Anyone curious about their marketing</p>
            </div>
            <div className="flex flex-row my-2">
              <TicketCheck fill={"green"} color='#fff' size={24} />
              <p>Business analysts</p>
            </div>
          </div>
          <div className="flex justify-center w-full">
            <Button variant="greengrape" size="lg" className="w-full">
              <a className="text-white font-bold" href="#cta">Get my free eBook!</a>
            </Button>
          </div>
        </div>

        <div className="flex-1 ebook max-h-[200px]">
          <div className=" relative  ">
            <div className="absolute -top-10 -right-2 rounded-full bg-green-800 w-24 h-24 hidden md:flex justify-around items-center">
              <p className="p-2 text-white">NEW RELEASE!</p>
            </div>
            <div className="hidden md:inline-block  object-cover w-full">
              <img className="h-full object-contain w-full max-h-[700px] shadow-md shadow-slate-500" src='/img/book-cover.png' />
            </div>
          </div>
        </div>
      </div>
 
      <div className="bg-[#242424] py-24 text-white">
        <p>LIMITED OFFER!</p>
        <p>Grab your free eBook and elevate your business</p>
        <CountdownTimer startTime={startTime} />
      </div>

      <div id="cta" className="w-full">

        <LoginForm />
      </div>
    </div>
  )
}

export default Ebook