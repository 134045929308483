import  { useState, useEffect } from 'react';

const CountdownTimer = ({ startTime }:any) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Set up interval to update countdown every second
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // Function to calculate the time remaining
  function calculateTimeLeft() {
    const targetTime = new Date(startTime.getTime() + 10 * 60 * 60 * 1000); // 10 hours from start time
    const now = new Date();
    const difference = targetTime.getTime() - now.getTime();

    // If countdown is complete
    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { hours, minutes, seconds };
  }

  return (
    <div>
      {/* <h2>Countdown Timer</h2> */}
      <div style={{ fontSize: '2.5rem' }}>
        {timeLeft.hours.toString().padStart(2, '0')}:
        {timeLeft.minutes.toString().padStart(2, '0')}:
        {timeLeft.seconds.toString().padStart(2, '0')}
      </div>
    </div>
  );
};

export default CountdownTimer;
