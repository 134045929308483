import { useEffect } from "react"
import { Button } from "./ui/button"

function Navbar() { 
  return (
    <div className=' flex flex-row justify-between items-center'>
      <a className='object-fit w-28 h-28' href='/'>
        <img alt='logo' src='/img/logo.png' className="cursor-pointer" />
      </a>

      <ul className='hidden md:flex flex-row nav-links'>
        <a href="/">Home</a>
        {/* <a href="#about">About</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a> */}
      </ul>

      {/* <Button> Contact Us </Button> */}

    </div>
  )
}

export default Navbar