
function Services() {
    return (
        <div id="services">
            <div className="flex flex-col md:flex-row justify-between items-center pt-12">
                <p className=" w-full   text-2xl md:text-4xl sm:text-6xl font-bold text-center md:text-left">Solidifying your digital footprint with <p className="text-green-600">Consistency</p></p>
                <p className="w-[100%] md:w-[40%] text-center md:text-right">Digital marketing company that ensures your online presence

                </p>
            </div>
            <div className="flex flex-col sm:flex-row">
                {/* /// single card */}
                <div className="border border-slate-500 rounded-md m-4 p-2 flex-1">
                    <p className="font-bold text-5xl text-green-600">*</p>
                    <img src='/illustrations/gummy-office-bag.svg' className=" h-72 w-full" />
                    <p className="font-bold text-green-600 text-xl" style={{
                        fontFamily: 'cursive'
                    }}>Social Media Marketing</p>
                    <p>Give your brand an unfair advantage with expert social media marketing. </p>
                </div>
                <div className="border border-slate-500 rounded-md m-4 p-2 flex-1">
                    <p className="font-bold text-5xl text-green-600">*</p>
                    <img src='/illustrations/pixeltrue-seo.svg' className="  h-72 w-full" />
                    <p className="font-bold text-green-600 text-xl" style={{
                        fontFamily: 'cursive'
                    }}>SEO Optimization</p>
                    <p>Be the first to pop up on all search engines and give your customers easy access to your brands</p>
                </div>
                <div className="border border-slate-500 rounded-md m-4 p-2 flex-1">
                    <p className="font-bold text-5xl text-green-600">*</p>
                    <img src='/illustrations/pixeltrue-icons-seo-2.svg' className="  h-72 w-full" />
                    <p className="font-bold text-green-600 text-xl" style={{
                        fontFamily: 'cursive'
                    }}>PPC Marketing</p>
                    <p>Our data-driven PPC campaigns put your brand in front of the right customers at the right time. Whether it’s Google Ads or retargeting, we optimize each ad to boost visibility and sales while minimizing your cost-per-acquisition.</p>
                </div>
            </div>
        </div>
    )
}

export default Services