
import { ArrowBigLeft, ArrowBigRight } from 'lucide-react';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';

function About() {
    const navigate = useNavigate()

    const goToEbookPage = () => {
        navigate('/ebook');
    }
    return (
        <div id="contact"> 

            <div className='p-4 flex flex-col  justify-center items-center w-full border border-slate-700 rounded-3xl my-32'>
                <div className='flex flex-col md:flex-row  justify-between w-full items-center'>
                    <div className='flex-1'>
                        <p className='text-3xl md:text-6xl text-left flex-1'>Want To Work <br /> With Us?</p>
                    </div>
                    <div className='object-fill relative  about-us-img   flex-[1.2]'>
                        <img alt='about-us-img' src='/img/img2.png' className='relative object-fill -top-10' />
                    </div>
                </div>

                <div className='border border-green-800 w-full my-4' />
                <div className='flex flex-col md:flex-row justify-between items-center'>
                <div className='flex flex-col md:flex-row   items-center flex-1'>
                        {/* <Button size='lg'  className='w-[70%] mx-2 my-1'>
                            Get Started <ArrowBigRight />
                        </Button> */}
                        <Button onClick={() => goToEbookPage()} size='lg' variant='greengrape' className='w-[70%] mx-2 my-1'   >
                            Get my free eBook!
                        </Button>
                    </div>
                    <div className='flex-1 '>
                        <p className='text-center md:text-right mt-4 md:mt-0'>Our free eBook resource provides you with ample guidelines to correctly organize and scale your business to channel the most leads.</p>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default About