import { useState } from 'react' 
import '@/App.css'
import Navbar from '@/components/Navbar'
import { Play, TicketCheck } from 'lucide-react';
import Services from '@/components/services'
import About from '@/components/About'
import Footer from '@/components/Footer'
import MainWrapper from '@/components/MainWrapper'
import { cn } from "@/lib/utils"
function Home() {
  const [count, setCount] = useState(0)

  return (
    <>
      <MainWrapper>
        <Navbar />
        <div>
          <p className='mt-12 flex flex-col justify-center items-center transition font-bold text-3xl lg:text-7xl md:text-5xl sm:text-6xl'><div className='flex flex-col sm:flex-row items-center justify-center pb-2'><p>Agency That </p><p className='text-green-600 pl-4'>Shapes </p></div><p>Business Visibility</p></p>

          <div className='flex flex-col sm:flex-row  relative my-24'>
            <div className="p-2 rounded-lg avatar-main-container flex flex-col sm:flex-row justify-start items-center border initial md:absolute top-0">
              <div className="avatar-group-stacked flex justify-start">
                <div className="avatar-wrapper w-[40px] h-[40px] rounded-full">
                  <img
                    className={` w-full rounded-full relative`}
                    src={
                      "https://framerusercontent.com/images/2Y7D9JS4krqAwKQy2bnTL8Hqfg.png"
                    }
                  />
                </div>
                <div className="avatar-wrapper w-[40px] h-[40px] rounded-full">
                  <img
                    className={` w-full rounded-full relative right-[.5rem]`}
                    src={
                      "https://framerusercontent.com/images/uNyXIhuGNBdSRHCDQcDtk8Wtjk.png"
                    }
                  />
                </div>
                <div className="avatar-wrapper w-[40px] h-[40px] rounded-full">
                  <img
                    className={` w-full rounded-full relative right-[1rem]`}
                    src={
                      "https://framerusercontent.com/images/2Y7D9JS4krqAwKQy2bnTL8Hqfg.png"
                    }
                  />
                </div>
              </div>
              <div className="">
                <div className="  right-2">
                  <p className={cn("text-black text-xl")}>
                    100+ customers
                  </p>
                  <div className="flex flex-row items-center justify-center">
                    <div className=" mr-2 flex flex-row items-center justify-center">
                      {[...Array(5).fill({})].map((x, i) => {
                        return (
                          <div className=" " key={i}>
                            <TicketCheck fill={"green"} color='#fff' size={24} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='hidden md:flex flex-row items-center mx-40 justify-center'>
              <div className='w-full'>
                <img src='/img/img8.png' className='center-img' />
              </div>
            </div>

            <div className=' initial md:absolute top-0 -right-10 w-[250px] text-left'>
              <p className=' '>Solidifying your digital footprint with consistency</p>
              <div className='flex flex-row justify-start items-center mt-2 ml-2'>

                <div className="pulsating-circle">

                </div>

                <p className='pl-4 font-bold pt-1'>Watch Video</p>
              </div>
            </div>
          </div>
        </div>
        <Services />

        {/* PROJECT WE DO */}

        <div id="about" className='bg-black mt-12 p-12 relative left-0 rounded-2xl w-full hidden sm:block'>
          <p className='text-white flex flex-col justify-center items-center transition font-bold text-3xl lg:text-7xl md:text-5xl sm:text-6xl'><div className='flex flex-row items-center justify-center pb-2'><p>Businesses Like </p><p className='text-green-600 pl-4'>You </p></div><p>We Serve</p></p>
          <p className='text-white pb-8'> We support businesses of all niches, some of which include these</p>

          <div className='grid grid-cols-1 md:grid-cols-2 mx-4'>
            <div className='relative shadow-md shadow-slate-600 roundex-lg mx-2 my-2'>
              <img
                className={` object-fit relative w-full h-full`}
                src={
                  "/img/projects/1.jpg"
                }
              />
              <div className='absolute bottom-5 rounded-lg left-0 w-full pl-[2.5%]'>
                <div className='shadow-md shadow-green-600 bg-white w-[95%] rounded-xl flex p-4 items-center justify-center'>
                  <p className='text-black pr-2'> Start up companies </p>
                  <TicketCheck fill={"green"} color='#fff' size={24} />
                </div>
              </div>
            </div>
            <div className='relative  shadow-md shadow-slate-600 rounded-lg my-2 mx-2'>
              <img
                className={` w-full h-full relative`}
                src={
                  "/img/projects/2.jpg"
                }
              />
              <div className='absolute bottom-5 rounded-lg left-0 w-full pl-[2.5%]'>
                <div className='shadow-md shadow-green-600 bg-white w-[95%] rounded-xl flex p-4 items-center justify-center'>
                  <p className='text-black pr-2'>Clothing E-commerce Store</p>
                  <TicketCheck fill={"green"} color='#fff' size={24} />
                </div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 mx-4 mt-4 rounded-lg'>
            <div className='relative  rounded-lg  shadow-md shadow-slate-600  mx-2  my-2'>
              <img
                className={` w-full h-full relative`}
                src={
                  "/img/projects/3.jpg"
                }
              />
              <div className='absolute bottom-5 rounded-lg left-0 w-full pl-[2.5%]'>
                <div className='shadow-md shadow-green-600 bg-white w-[95%] rounded-xl flex p-4 items-center justify-center'>
                  <p className='text-black pr-2'>Large Scale Firms</p>
                  <TicketCheck fill={"green"} color='#fff' size={24} />
                </div>
              </div>
            </div>
            <div className='relative shadow-md shadow-slate-600  mx-2 rounded-lg'>
              <img
                className={` w-full h-full relative`}
                src={
                  "/img/projects/4.jpg"
                }
              />
              <div className='absolute bottom-5 rounded-lg left-0 w-full pl-[2.5%]'>
                <div className='shadow-md shadow-green-600 bg-white w-[95%] rounded-xl flex p-4 items-center justify-center'>
                  <p className='text-black pr-2'>Oil E-commerce Stores</p>
                  <TicketCheck fill={"green"} color='#fff' size={24} />
                </div>
              </div>
            </div>
          </div>
        </div>
 
        <About />
        <Footer />
      </MainWrapper>
    </>
  )
}

export default Home
