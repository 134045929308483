

import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import axios from "axios";
import { useState } from "react";

export function LoginForm() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null); // Clear previous messages
console.log('at least')
    try {
      const response = await axios.post('/api/sendEmail', { to: email, firstName });
      setMessage(response.data.message); // Set success message
    } catch (error: any) {
      console.log(error)
      setMessage(error.response?.data?.message || 'Error sending email');
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <Card className="mx-auto w-full py-24">
      <CardHeader>
        <CardTitle className="text-2xl">Get Your Free PDF eBook</CardTitle>
        <CardDescription>
          Please provide your details to get access to the free PDF
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid gap-4">
          <div className="grid gap-2">

            <div className="grid gap-2">
              <div className="flex items-start">
                <Label htmlFor="name">First Name</Label>
              </div>
              <Input id="name" type="text" onChange={(e) => setFirstName(e.target.value)} placeholder="John" />
            </div>
            <div className="flex items-start">
              <Label htmlFor="email">Email</Label>
            </div>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <Button disabled={loading} variant="greengrape" type="submit" onClick={(e) => handleSubmit(e)} className="w-full">
            {loading ? 'Sending...' : 'Get my PDF'}
          </Button>
          {message && <p>{message}</p>}
        </div>
      </CardContent>
    </Card>
  )
}
